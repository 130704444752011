<template>
  <div :class="$style.list">
    <div :class="$style.wrapper"></div>
    <el-table :data="pages" stripe>
      <el-table-column label="Название">
        <template slot-scope="scope">
          {{ getName(scope.row.slug) }}
        </template>
      </el-table-column>
      <el-table-column prop="slug" label="Путь"> </el-table-column>
      <el-table-column prop="site" label="Сайт" width="170">
        <template slot-scope="scope">
          <a
            v-if="scope.row.slug !== 'site-settings'"
            :href="$configData.sellers_link + scope.row.slug"
          >
            Перейти
          </a>
        </template>
      </el-table-column>
      <el-table-column prop="edit" label="Редактирование" width="170">
        <template slot-scope="scope">
          <router-link
            :to="`${$options.ADDSELLERS_ADMIN_ROUTES.CONTENT.PAGES.INDEX}/${scope.row.slug}`"
          >
            Перейти
          </router-link>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import delivery from '@/delivery'
import { ADDSELLERS_ADMIN_ROUTES } from '@/constants/routing/index.js'
export default {
  ADDSELLERS_ADMIN_ROUTES,
  data() {
    return {
      pages: [],
      total: 0
    }
  },
  computed: {
    pagesName() {
      return [
        {
          value: ADDSELLERS_ADMIN_ROUTES.CONTENT.PAGES.RETAILERS.PATH,
          label: 'Ритейлеры'
        },
        { value: '', label: 'Главная' },
        {
          value: ADDSELLERS_ADMIN_ROUTES.CONTENT.PAGES.SITE_SETTINGS.PATH,
          label: 'Общие настройки'
        },
        {
          value: ADDSELLERS_ADMIN_ROUTES.CONTENT.PAGES.PUBLIC_OFFER.PATH,
          label: 'Публичная страница'
        },
        {
          value: ADDSELLERS_ADMIN_ROUTES.CONTENT.PAGES.CONFIDENTIAL_POLICY.PATH,
          label: 'Политика конфеденциальности'
        },
        {
          value: ADDSELLERS_ADMIN_ROUTES.CONTENT.PAGES.SUPPLIERS.PATH,
          label: 'Поставщики'
        },
        {
          value: ADDSELLERS_ADMIN_ROUTES.CONTENT.PAGES.BONUS_PROGRAM.PATH,
          label: 'Бонусная программа'
        }
      ]
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getName(slug) {
      return this.pagesName.find((page) => page.value === slug).label
    },
    async getList() {
      const loading = this.$loading({
        lock: true
      })

      const { value, error } =
        await delivery.ContentServiceCore.PagesActions.getList('sellers')
      if (error) return
      this.pages = value.data
      this.total = value.meta.count

      loading.close()
    }
  }
}
</script>

<style lang="scss" module>
.list {
  padding: 1rem;
  .wrapper {
    display: flex;
    justify-content: flex-end;
    .button {
      margin-bottom: 1rem;
    }
  }
  .pagination {
    @include pagination;
  }
}
</style>
